import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton } from '@mui/material';
// config
import { NAV } from '../../../../config-global';
// components
import Logo from '../../../../components/logo';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import NavList from './NavList';
import { useScrollSpy } from '../../../../utils/useScrollSpy';
// ----------------------------------------------------------------------
export default function NavMobile({ isOffset, data }) {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        scrollToSection(pathname.split('/')[1]);
    }, [pathname]);
    const ids = ['categories', 'about', 'location'];
    const activeId = useScrollSpy(ids, 54);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: handleOpen, sx: {
                    ml: 1,
                    ...(isOffset && {
                        color: 'text.primary',
                    }),
                }, children: _jsx(Iconify, { icon: "eva:menu-2-fill" }) }), _jsx(Drawer, { open: open, onClose: handleClose, PaperProps: {
                    sx: {
                        pb: 5,
                        width: NAV.W_BASE,
                    },
                }, children: _jsxs(Scrollbar, { children: [_jsx(Logo, { sx: { mx: 2.5, my: 3 } }), _jsx(List, { component: "nav", disablePadding: true, children: data.map((link) => (_jsx(NavList, { item: link, activeId: activeId }, link.title))) })] }) })] }));
}
